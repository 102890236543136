.navbar_container {
  /* position: fixed;
  z-index: 21; */
  width: 100vw;
  height: 45px;

  border: 1px solid #fff;
  background: #fff;
  box-shadow: 2px 6px 12px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  padding: 0 80px 0 80px !important;
}

.navbar_container .logo_container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.navbar_container .logo_container > div {
  height: 35px;
}

.navbar_container .logo_container > div img {
  height: 100%;
}

.profile_container {
  display: flex;
  align-items: center;
  font-size: 16px;
}

.profile_container .cart_container,
.profile_container .notification_container,
.reminder_container {
  position: relative;
  line-height: 1px !important;
}

.profile_container .cart_container img {
  height: 25px;
  margin-right: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.profile_container .notification_container img {
  height: 60px;
  padding: 16px 10px;
  margin-right: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.cart_container .cart_count {
  position: absolute;
  height: 18px;
  padding: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  background: #e10000;
  border: 1px solid white;
  border-radius: 5px;
  color: #fff;
  text-align: center;
  font-size: 11px;
  top: -5px;
  right: 8px;
}

.notification_container .notification_count {
  border-radius: 5px;
  position: absolute;
  height: 18px;
  padding: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2px;
  background: #e10000;
  border: 1px solid white;
  color: #fff;
  text-align: center;
  font-size: 11px;
  top: 12px;
  right: 14px;
  cursor: pointer;
}

.user_profile {
  display: flex;
  align-items: center;
  font-weight: 600;
  padding-left: 10px;
  cursor: pointer;
}

.user_profile .user_img {
  width: 35px;
  height: 35px;
  border-radius: 25px;
  margin-right: 20px;
  padding: 5px;
  background: #e9e9e9;
}

.user_profile > div {
  line-height: 18px !important;
  font-size: 12px;
}

.user_profile .user_name_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.user_profile .user_name_container div {
  color: #aaaaaa;
  font-weight: 400;
}

.user_profile .user_name_container .dropdown_icon {
  transform: rotate(0deg);
  transition: transform 400ms ease-in-out;
  width: 20px;
  height: 20px;
}

.user_profile .user_name_container .dropdown_icon_active {
  width: 20px;
  height: 20px;
  transform: rotate(180deg);
  transition: transform 400ms ease-in-out;
}

.dropdown_container {
  position: absolute;
  opacity: 0;
  transition: all 0.2s;
  transition-delay: 0.2s;
  z-index: 0;
  opacity: 0;
  scale: 0;
  top: 0;
  margin-top: 50px;
  right: 60px;
  border-radius: 0px 0px 10px 10px;
  background: linear-gradient(
    121deg,
    rgba(255, 255, 255, 0.7) 0%,
    rgba(255, 255, 255, 0.4) 100%
  );
  box-shadow: 0px 4px 31px 4px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(40px);
}

.dropdown_container ul {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: justify;
  font-size: 16px;
  line-height: 40px;
  font-weight: 600;
}
.org_list_cantainer {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  background: #fff;
  max-height: 300px;
  overflow-y: auto;
}
.dropdown_container ul li {
  margin: 10px 5px;
  border-radius: 4px !important;
  display: flex;
  align-items: center;
  font-style: normal;
  letter-spacing: 0.16px;
}
.dropdown_container ul li:hover {
  background: rgba(0, 0, 0, 0.05);
}
.dropdown_container ul li > img {
  width: 30px;
  height: 30px;
  border-radius: 50px;
  margin-left: 5px;
  margin-right: 10px;
  padding: 3px;
  background-color: #fff;
}
.active {
  opacity: 1;
  z-index: 3;
  transition: all 0.3s;
  transition-delay: 0.2s;
  transition-property: opacity;
  scale: 1;
}
.org_list {
  margin-left: 20px;
  width: 100%;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.active_org {
  background: linear-gradient(126deg, #322e80 0%, rgba(50, 46, 128, 0.7) 100%);
  cursor: pointer;
}
.active_org span {
  color: #fff !important;
}
.active_org:hover {
  background: linear-gradient(
    126deg,
    #322e80 0%,
    rgba(50, 46, 128, 0.7) 100%
  ) !important;
  color: #fff;
}
.check_in::after {
  content: "";
  border: 1px solid #aaaaaa;
  padding: 10px 0;
  margin-left: 10px;
}

.start_day_btn {
  border: none;
  background: transparent linear-gradient(116deg, #b0abf8b5 0%, #6053ff22 100%)
    0% 0% no-repeat padding-box;
  padding: 10px 20px;
  border-radius: 6px;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
  width: 130px;
}
.start_day_btn:hover {
  background: transparent linear-gradient(116deg, #e5e3ffb5 0%, #2111c922 100%)
    0% 0% no-repeat padding-box;
}

.profile_view_container {
  line-height: 40px;
  background: linear-gradient(
    90deg,
    #fff 1.65%,
    rgba(255, 255, 255, 0.7) 98.92%
  );

  box-shadow: 0px 4px 31px 4px rgba(0, 0, 0, 0.1);
  font-family: Poppins;
  font-weight: 500;
}
.profile_header {
  display: flex;
  justify-content: space-between;
  width: 63%;
  padding: 0px 20px;
}
.profile_body {
  padding: 10px 20px 0px 20px;
  border-top: 1px solid #eee;
}
.header_top {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}
.header_bottom {
  text-align: center;
  font-size: 13px;
  line-height: 15px;
}
.list_group {
  margin-top: 10px;
}
.list_group img {
  width: 18px;
}

.profile_view_header {
  display: flex;
  justify-content: space-between;
  width: 77%;
  padding: 0px 20px;
}
.profile_view_list {
  padding: 10px 20px 0px 20px;
  line-height: normal;
  max-height: 75vh;
  overflow: scroll !important;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.reminder_container img {
  width: 30px;
}

.reminder_count {
  border-radius: 5px;
  position: absolute;
  height: 18px;
  padding: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2px;
  background: #e10000;
  border: 1px solid white;
  color: #fff;
  text-align: center;
  font-size: 11px;
  top: -3px;
  cursor: pointer;
}

.profile_view_tabs {
  border-top: 1px solid #eee;
  display: flex;
  background: linear-gradient(
    107.41deg,
    rgba(255, 255, 255, 0.7) 0%,
    rgba(255, 255, 255, 0.4) 100%
  );
  box-shadow: 2px 6px 12px 4px #00000005;
  text-align: center;
  line-height: 3;
  cursor: pointer;
}
.active_profile_view_tab {
  color: #322e80;
  border-bottom: 2px solid;
}
