.main_container {
  background-color: white;
  border-radius: 8px;
  padding: 1.5em;
  display: flex;
  flex-direction: column;
  gap: 1em;
  font-family: "Poppins";
}
.main_container p {
  margin: 0;
}
.heading {
  display: flex;
  align-items: center;
  gap: 1em;
  font-size: 18px;
  font-weight: 600;
}
.space_between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.btn {
  align-self: flex-end;
}
