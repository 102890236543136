body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --color-theme-1: #312b81;
  --color-theme-2: #0a9b11;
  --color-theme-3: #1677ff;
  --color-subtitle: #727176;
  --color-error: #ff0000;
  --font-family: "Poppins", sans-serif;
  --app-background-1: linear-gradient(
    135.88deg,
    #fefcfc -0.14%,
    #fcf2f6 26.68%,
    #ebe7f3 60.17%,
    #e4f5f9 99.61%
  );
  --app-background-2: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.35) 2%,
    rgba(255, 255, 255, 0.2) 98%
  );
  --app-foreground-1: linear-gradient(90deg, #322e80 8%, #322e80b3 92%);
  --app-shadow-left-bottom: 4px 10px 31px 4px #0000001a;
}

/* App.css */

.app-root {
  min-width: 1200px;
}

.app-layout {
  height: 100vh;
}

.app-content {
  height: 100%;
  overflow: auto;
  padding: 20px 35px 20px 120px;
  background: var(--app-background-1);
}

.refactor .dashboard_analytics iframe {
  border: none;
  height: calc(100vh - 170px) !important;
}

/* shorthand classes */

.widget {
  background: var(--app-background-2);
}

.refactor h1 {
  font-size: 30px;
  margin: 0px;
}

.refactor h2 {
  font-size: 25px;
  margin: 0px;
}

.refactor h3 {
  font-size: 21px;
  margin: 0px;
}

.refactor h4 {
  font-size: 16px;
  font-weight: 400;
  margin: 0px;
}

.refactor h5 {
  font-size: 12px;
  font-weight: 400;
  margin: 0px;
}

.refactor p {
  font-size: 14px;
  margin: 0px;
}

.refactor .p24 {
  padding: 24px;
}

.refactor .r10 {
  border-radius: 10px;
}

.refactor .subtitle {
  color: var(--color-subtitle);
}

.refactor .clickable {
  user-select: none;
  cursor: pointer;
}

.refactor .center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.refactor .scrollview {
  overflow-y: auto;
  /* padding-right: 10px; */
}

.refactor .ant-tabs-nav {
  border-bottom: 2px solid #fff;
}

/* overrides */

/*      for rounded carousel dots     */
.refactor .custom_carousel_dots li button {
  width: 10px !important;
  height: 10px !important;
  border-radius: 100% !important;
  background: var(--color-theme-1) !important;
  margin-top: 22px !important;
}
.refactor .custom_carousel_dots li.slick-active {
  width: 16px !important;
}

.refactor .ant-input.ant-input-lg {
  font-size: 15px;
  padding: 10px 15px;
}

.refactor .ant-btn-lg {
  height: 44px;
}

/* side navbar */
.side-navbar {
  position: fixed !important;
  z-index: 10;
  height: calc(100vh - 45px);
  background-color: #fff !important;
  padding: 10px 5px;
  overflow-y: auto;
  overflow-x: hidden;
}
.side-navbar.ant-layout-sider .collapse-item {
  opacity: 0;
  animation: fade-in-anim 0.1s 0.2s forwards;
}
.side-navbar.ant-layout-sider-collapsed .collapse-item {
  opacity: 0 !important;
}
.side-navbar .ant-collapse-header {
  height: 52px !important;
}
.side-navbar.ant-layout-sider-collapsed [role="button"] {
  width: 52px !important;
  border-radius: 50% !important;
}
.side-navbar [role="button"]:has([item-active="true"]) {
  background: var(--app-foreground-1);
}
.side-navbar
  [role="button"]:has([item-active="true"])
  .ant-collapse-expand-icon {
  color: #fff;
}
.side-navbar [role="button"] {
  padding: 14px 16px !important;
  border-radius: 0px 20px 20px 0px !important;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: border-radius 0.2s ease-out;
}
.side-navbar [role="button"]:hover {
  background-color: #f4f4f4 !important;
  transition: background-color 0.1s ease-out;
}
.side-navbar.ant-layout-sider-collapsed .ant-collapse-content {
  display: none;
}

/* animation classes */

.hover-focus {
  opacity: 0.6;
  cursor: pointer;
}
.hover-focus:hover {
  animation: focus-anim 0.2s;
  opacity: 1;
}

/* animation keyframes */

@keyframes fade-in-anim {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes focus-anim {
  0% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}
