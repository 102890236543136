.activity_card {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  border: 4px solid #fff;
  border-radius: 10px;
  background: linear-gradient(
    121deg,
    rgba(255, 255, 255, 0.7) 0%,
    rgba(255, 255, 255, 0.4) 100%
  );
  box-shadow: 2px 6px 12px 4px rgba(0, 0, 0, 0.02);
  padding: 1em;
  cursor: pointer;
  justify-content: center;
}
.activity_card p {
  margin: 0;
}
.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5em;
}
.extra_space {
  gap: 6em;
}
.activity_name {
  font-size: 16px;
  font-weight: bold;
  text-transform: capitalize;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 260px;
}
.activity_address {
  color: #727176;
  font-size: 12px;
  font-weight: 500;
}
.activity_address span {
  text-transform: capitalize;
}
.activity_date {
  color: #727176;
  font-size: 12px;
  font-weight: 500;
}
.activity_created_by {
  color: #3a3486;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
}
.activity_message {
  background: #fff4ef;
  padding: 0.5em 1em;
  border-radius: 10px;
  overflow-wrap: break-word;
}
.module_type {
  color: #3a3486;
  font-size: 15px;
  font-weight: 600;
  /* position: absolute; */
}
