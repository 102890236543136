.filter {
  padding: 0 !important;
  font-family: Poppins;
  right: 0;
  width: 500px;
}

.filter_container > div {
  line-height: 40px;
}
.filter_list {
  background: #f4f4f4;
  border: 1px solid #eeeeee;
  color: #727176;
}
.filter_list_date_type {
  height: 268px;
  padding: 20px 10px;
}
.option {
  padding: 0 10px;
  cursor: pointer;
  border: 1px solid #eeeeee;
}
.active_option {
  color: #000;
  font-size: 15px;
  background: #ffff;
}
.list_group {
  display: flex;
  align-items: center;
  gap: 10px;
  border-top: 1px solid #eeeeee;
  padding: 0 10px;
  cursor: pointer;
}
.filter_footer {
  height: 70px;
  padding: 0px 20px;
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  border-top: 2px solid #eeeeee;
  align-items: center;
}

/* sort by filter */
.sort_dropdown_container {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  padding: 0;
  margin: 0;
  overflow: hidden;
}
.sort_dropdown_container > li {
  padding: 10px 20px;
  background: #f4f4f4;
  cursor: pointer;
  font-size: 13px;
  color: #727176;
  list-style: none;
  position: relative;
}
.sort_dropdown_container .active_filter {
  background: #fff;
  color: #000;
}
.sort_dropdown_container .active_filter:before {
  content: "";
  background: #000;
  border-radius: 50%;
  border: 4px solid #000;
  position: absolute;
  margin-top: 6px;
  margin-left: -12px;
}
