.dropdown_container > div:first-child {
  display: flex;
  border: 1px solid #d9d9d9;
  width: 500px !important;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
}

.dropdown_container input {
  font-size: 16px;
  border: none;
  width: 100%;
}

.dropdown_container > div > div {
  margin-right: 10px;
  color: #cccccc;
}

.dropdown_container ul {
  position: absolute;
  margin-top: 3px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  z-index: 10;
  background: #fff;
  width: 500px;
  max-height: 200px;
  overflow: auto;
  list-style: none;
  padding: 0;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.dropdown_container li {
  margin: 0 5px;
  padding: 10px;
}
.dropdown_container li:hover {
  background-color: #f5f5f5;
}

.table_header_name_list {
  font-weight: 600;
  margin-left: 10px;
  color: black;
}
.table_header_name_list > span:last-child {
  font-size: 10px;
  color: #7d7d7d;
}
.contact_person_name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #000;
  font-weight: 600;
}

.add_icon {
  background: #fff;
  border-radius: 25px;
  margin-right: 10px;
}

.search_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

/* Add customer */
.add_edit_from {
  width: 50%;
  margin: 80px auto;
}

.form_top_section {
  display: flex;
  border-bottom: 4px solid #fff;
  background: linear-gradient(
    121deg,
    rgba(255, 255, 255, 0.7) 0%,
    rgba(255, 255, 255, 0.4) 100%
  );
  backdrop-filter: blur(10px);
  position: fixed;
  z-index: 1;
  width: 100vw;
  height: 70px;
  overflow: hidden;
  left: 0;
  top: 45px;
}

.form_top_section > div {
  display: flex;
  align-items: center;
  width: 50%;
  margin: auto;
  justify-content: space-between;
}

.form_top_section > div > div:first-child {
  font-size: 20px;
  color: #727176;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: 70px;
}

.form_top_section > div > div:last-child {
  display: flex;
  flex-direction: row-reverse;
}

.form_top_section button {
  margin-left: 20px;
  font-size: 12px !important;
  font-weight: 500 !important;
}

.add_edit_from h2 {
  display: flex;
  align-items: center;
  font-size: 16px;
}

.add_edit_from h2 img {
  cursor: pointer;
}

.add_edit_from .form {
  border-radius: 10px;
  border: 4px solid #fff;
  background: linear-gradient(
    121deg,
    rgba(255, 255, 255, 0.7) 0%,
    rgba(255, 255, 255, 0.4) 100%
  );
  box-shadow: 2px 6px 12px 4px rgba(0, 0, 0, 0.02);
  padding: 20px;
  margin-top: 20px;
}

.form_header {
  font-size: 16px;
  font-weight: 600;
  /* margin-bottom: 20px; */
}

.add_edit_from form label {
  display: block;
  font-size: 13px;
  font-weight: 400;
  margin-top: 20px;
  margin-bottom: 5px;
}

.add_edit_from form label span {
  color: #f00;
}

.add_edit_from form input,
.add_edit_from form select {
  width: 100%;
  /* padding: 5px 0; */
}

.add_edit_from .form_button {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin-top: 20px;
}

.add_edit_from .form_button button {
  font-size: 13px !important;
  margin-left: 20px;
}

.search_head {
  display: flex;
  justify-content: space-between;
}

.search_head b {
  color: #001a72;
  margin: 0px;
  display: block;
  font-size: 13px;
  margin-top: 20px;
  margin-bottom: 5px;
  cursor: pointer;
}

/* Preview form */
.assign_list_form_header {
  font-size: 16px;
  font-weight: 600;
  padding: 20px 10px;
}
.assign_list_box {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #fff;
  align-items: center;
  border-bottom: 1px solid #eee;
}
.assign_list_box > div p {
  margin: 0px;
  font-size: 11px;
  color: #727176;
}
.form_details {
  display: flex;
  gap: 200px;
}
.form_details_box {
  width: 200px;
}

/* error css */
.input_error {
  border: 2px solid rgb(255, 120, 120) !important;
}

.error {
  color: #f00;
  font-size: 11px;
}

.customer_level_label {
  position: absolute;
  margin: 0;
  background: red;
  transform: rotate(180deg);
  writing-mode: vertical-rl;
  border-radius: 5px 0 0 5px;
  left: -26px;
  top: -8px;
  bottom: -8px;
  font-size: 10px;
  padding: 0px 2px;
  text-align: center;
}

/* review page */
.form_section {
  border-radius: 10px;
  border: 2px solid #fff;
  background: linear-gradient(
    107deg,
    rgba(255, 255, 255, 0.7) 0%,
    rgba(255, 255, 255, 0.4) 100%
  );
  box-shadow: 2px 6px 12px 0px rgba(0, 0, 0, 0.05);
  padding: 0px 20px 10px 20px;
  font-family: Poppins;
  margin-bottom: 20px;
}

.section_group {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.section_group > div {
  width: calc(50% - 10px);
}
.review_label {
  color: #727176;
  font-weight: 500;
}

.review_value {
  color: #000;
  font-size: 16px;
  font-weight: 500;
  word-break: break-all;
}

.customer_name {
  background: #fff !important;
}
.link_tag {
  color: #312b81;
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
}
