.loader_modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999990;
  background: rgb(243 240 240 / 70%);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(2px);
  animation:  200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.loader {
  border: 4px solid rgb(18, 18, 65);
  border-left-color: transparent;
  border-radius: 50%;
}

.loader {
  border: 4px solid rgb(18, 18, 65);
  border-left-color: transparent;
  width: 36px;
  height: 36px;
}

.loader {
  border: 4px solid rgb(18, 18, 65);
  border-left-color: transparent;
  width: 36px;
  height: 36px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
@keyframes scale-in-center {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/*         horizontal line loader animation           */
.line_loader {
  display: block;
  width: 250px;
  height: 6px;
  border-radius: 30px;
  background-color: #e4e6eb;
  position: relative;
}

.line_loader::before {
  content: "";
  position: absolute;
  background: linear-gradient(126deg, #322e80 0%, rgba(50, 46, 128, 0.84) 100%);
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
  border-radius: 30px;
  animation: linear-infinite 1s ease-in-out infinite;
}

@keyframes linear-infinite {
  50% {
    width: 100%;
  }
  100% {
    width: 0;
    right: 0;
    left: unset;
  }
}
