.pricing-group-table .ant-table-body,
.pricing-group-table .ant-table-placeholder {
  height: calc(100vh - 315px);
}
.pricing-group-table td.ant-table-cell {
  background: #f4f4f4 !important;
  padding: 0 !important;
  border-bottom: 1px solid #f4f4f4 !important;
}
.pricing-group-table td.action-white-cell {
  background: #fff !important;
}
