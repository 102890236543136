.custom-select_received .ant-select-selector {
  background-color: #f0f8fe !important;
  color: #1d97f5 !important;
  border-color: #fff !important;
}
.custom-select_approved .ant-select-selector {
  background-color: #fef8f1 !important;
  color: #f1a248 !important;
  border-color: #fff !important;
}
.custom-select_shipped .ant-select-selector {
  background-color: #eefdf3 !important;
  color: #288948 !important;
  border-color: #fff !important;
}
.custom-select_rejected .ant-select-selector {
  background-color: #fef0f1 !important;
  color: #f44147 !important;
  border-color: #fff !important;
}
.custom-select .ant-select-selector {
  background-color: rgba(50, 46, 128, 0.84) !important;
  color: #fff !important;
  border-color: #fff !important;
}
.custom-select .ant-select .ant-select-arrow {
  color: #fff !important;
}
.custom-select
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item {
  color: #fff !important;
}
