.activity_header_card p {
  margin: 0;
}
.activity_header {
  display: flex;
  justify-content: space-between;
  padding: 1em 1.5em;
  color: #727176;
  font-weight: 600;
}
.flex_5 {
  display: flex;
  align-items: center;
  gap: 0.5em;
}
.date_btns {
  border: none;
  background: transparent;
  outline: none;
  font-size: 20px;
  color: #727176;
}
.date_btns:disabled {
  opacity: 0.5;
}
.activity_details {
  border-radius: 10px;
  border: 4px solid #fff;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.7) 1.65%,
    rgba(255, 255, 255, 0.4) 98.92%
  );
  box-shadow: 2px 6px 12px 4px rgba(0, 0, 0, 0.02);
}
.activity_details_list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 1em 1.5em;
  row-gap: 2em;
}
.activity_count {
  font-size: 18px;
  color: #001a72;
  font-weight: 600;
}
/* .selected_map {
  position: relative;
  border-radius: 4px;
  border: 2px solid #fff;
  background: linear-gradient(96deg, #ebeafd 1.26%, #d3d0f9 93.63%);
  box-shadow: 2px 6px 12px 4px rgba(0, 0, 0, 0.02);
  display: flex;
  justify-content: space-between;
  font-weight: 600;
}
.link {
  color: #000;
  gap: 0.5em;
  padding-inline: 0.5em;
  width: 130px;
}
.link:hover {
  color: #000;
  transition: 0.2s ease-out;
}
.down_arr {
  border-left: 1px solid #ffffff;
  padding-inline: 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.mapdd_menu {
  position: absolute;
  top: 110%;
  background: #fff;
  width: 100%;
  border-radius: 10px;
  padding: 0.5em 0;
}
.map_dd_links:hover {
  background: #f0f0f6;
}*/
.sales_report {
  background: linear-gradient(145deg, #e2e1e8 10.12%, #c9c7dd 93.34%);
  padding-block: 1em;
  text-align: center;
  color: #312b81;
  font-weight: 600;
  cursor: pointer;
  border-radius: 0 0 5px 5px;
}
