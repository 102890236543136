.flex {
  display: flex;
  gap: 1em;
  align-items: center;
}
.flex_col {
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.space_between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.linear_background {
  background: linear-gradient(
    107.41deg,
    rgba(255, 255, 255, 0.7) 0%,
    rgba(255, 255, 255, 0.4) 100%
  );
}
.module_switch_options {
  border: 1px solid #ffffff;
  box-shadow: 2px 6px 12px 4px #00000005;
  border-radius: 10px;
  padding-inline: 10px;
  font-size: 14px;
  color: #727176;
  cursor: pointer;
}
.active_module {
  border: 1px solid #dddddd;
  background: #ffffff;
  color: #322e80;
  padding-inline: 20px;
  margin-block: 5px;
  border-radius: 8px;
  box-shadow: 2px 6px 12px 0px #0000000d;
  transition: border 0.2s ease-out, color 0.2s ease-out,
    box-shadow 0.2s ease-out;
}
.filter_options {
  display: flex;
  gap: 5em;
  border-bottom: 2px solid #fff;
}
.filter_name {
  color: #727176;
  cursor: pointer;
  font-weight: 600;
  margin: 0;
}
.add_icon {
  background: #fff;
  border-radius: 25px;
  margin-right: 10px;
}
.dashed {
  width: 15px;
  height: 1px;
  background-color: #000000;
  margin: auto;
}
.staff_img {
  width: 30px;
  height: 30px;
  border-radius: 100%;
}
.active_filter {
  border-bottom: 3px solid #322e80;
  color: #322e80;
}

/*            add/edit form               */
.form_field_background {
  border: 2px solid #ffffff;
  box-shadow: 2px 6px 12px 0px #0000000d;
  padding: 1em 1.5em;
  border-radius: 10px;
}
.card_title {
  font-weight: 600;
  font-size: 18px;
  color: #000000;
}
.remove_icon {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(236, 236, 236);
  border-radius: 100%;
  display: flex;
  padding: 3px 3px 4px 4px;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.menu_tag {
  border-radius: 15px;
  gap: 5px;
  padding: 2px 15px;
}
.choose_staff_container {
  background: linear-gradient(
    93.95deg,
    rgba(255, 255, 255, 0.7) 8.19%,
    rgba(255, 255, 255, 0.4) 91.78%
  );
  border: 1.5px solid #ffffff;
  border-radius: 10px;
  box-shadow: 2px 6px 12px 0px #0000000f;
  overflow: hidden;
}
