.form_item_card {
  background: linear-gradient(
    107.41deg,
    rgba(255, 255, 255, 0.7) 0%,
    rgba(255, 255, 255, 0.4) 100%
  );
  border: 2px solid #ffffff;
  box-shadow: 2px 6px 12px 0px #0000000d;
  padding: 20px;
  border-radius: 10px;
}
.space_between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.link {
  color: #312b81;
  text-decoration: underline;
  font-size: 600;
  font-size: 18px;
  cursor: pointer;
}
.add_edit_form_container {
  width: 60%;
  margin: 0 auto;
}
.existing_mapped_list_container {
  max-height: 300px;
  overflow-y: scroll;
}
.bullet {
  width: 5px;
  height: 5px;
  border-radius: 100%;
  background: #000;
}
/*            manage  price table       */
.nested_cell {
  height: 67px;
  place-content: center;
}
.product_img {
  width: 50px;
  height: 50px;
  border-radius: 4px;
}
.proxy {
  width: 40px;
  height: 8px;
  background-color: #dddddd;
  border-radius: 10px;
}
.price_inputs input,
.price_inputs {
  padding: 5px !important;
}
.price_inputs .ant-input-suffix {
  color: #727176 !important;
}
.qty_input {
  padding-block: 10px !important;
}
.action_btns {
  display: flex;
  align-items: center;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #ffffff;
}
